import React from 'react';
import {Text} from 'wix-ui-tpa';
import {Checkboxes} from '../../../../Checkboxes/Checkboxes';
import {PlaceOrderButton} from '../../../../PlaceOrderButton/PlaceOrderButton';
import {classes} from './PlaceOrderOpen.st.css';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {PlaceOrderDataHook} from '../PlaceOrderStep';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {Violations} from '../../../../Violations/Violations';

export const PlaceOrderOpen = () => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {shouldShowViolations},
  } = useControllerProps();

  return (
    <div className={classes.root} data-hook={PlaceOrderDataHook.open}>
      <div className={classes.subtitle}>
        <Text data-hook={PlaceOrderDataHook.subtitle}>{localeKeys.checkout.place_order.description()}</Text>
      </div>
      <Checkboxes />
      {shouldShowViolations && <Violations dataHook={PlaceOrderDataHook.violations} />}
      <PlaceOrderButton isMobile={false} />
    </div>
  );
};
