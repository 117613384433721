import {FIELDS_GAP, FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {FormProps} from '../ContactForm/ContactForm';
import {useControllerProps} from '../../Widget/ControllerContext';
import {CashierMandatoryFieldsOverrides} from '../../../../types/app.types';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {getAddressFormOverrides} from './addressForm.utils';

export interface AddressFormProps extends FormProps {
  overrides?: CashierMandatoryFieldsOverrides;
}

const AddressFormInternal = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  overrides,
}: AddressFormProps) => {
  const {experiments} = useExperiments();
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const i18n = useICUI18n();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={getAddressFormOverrides({experiments, checkoutSettings, overrides})}
      formId={FORMS_TEMPLATE_IDS.ADDRESS}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const AddressForm = React.memo(AddressFormInternal);
