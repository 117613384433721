import React from 'react';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {CustomerDetailsStepDataHook} from '../CustomerDetailsStep';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';
import {classes} from './CustomerDetailsCollapsed.st.css';
import {SPECS} from '../../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export const CustomerDetailsCollapsed = () => {
  const {experiments} = useExperiments();
  const {
    checkoutStore: {checkout, isShippingFlow},
  } = useControllerProps();
  const address = isShippingFlow ? checkout.shippingDestination?.address : undefined;

  return (
    <div data-hook={CustomerDetailsStepDataHook.collapsed} className={classes.root}>
      <ContactAndAddressSummary
        contact={checkout.shippingDestination?.contact}
        email={checkout.buyerInfo.email}
        customField={checkout.customField}
        address={address}
      />
      {checkout.selectedShippingOption?.pickupInfo &&
        !(
          experiments.enabled(SPECS.UseNewCheckoutInVisitorShippingAndDigitalFlows) ||
          experiments.enabled(SPECS.UseNewCheckoutInRestOfTheFlows)
        ) && <PickupInfoBox pickupInfo={checkout.selectedShippingOption.pickupInfo} />}
    </div>
  );
};
