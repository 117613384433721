import React, {useEffect} from 'react';
import {Checkbox, Text} from 'wix-ui-tpa';
import {useMemberDetailsData} from '../WithMemberDetailsData';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';

export enum SetAsDefaultCheckboxDataHook {
  setAsDefaultCheckboxToggle = 'SetAsDefaultCheckbox.setAsDefaultCheckboxToggle',
  setAsDefaultCheckboxLabel = 'SetAsDefaultCheckbox.setAsDefaultCheckboxLabel',
}
export const SetAsDefaultCheckbox = () => {
  const {isSetAsDefaultChecked, setIsSetAsDefaultChecked, selectedAddressesServiceId} = useMemberDetailsData();
  const {memberStore: defaultAddressId} = useControllerProps();
  const localeKeys = useLocaleKeys();

  useEffect(
    () => {
      if (selectedAddressesServiceId === defaultAddressId.defaultAddressId) {
        setIsSetAsDefaultChecked(true);
      } else {
        setIsSetAsDefaultChecked(false);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [selectedAddressesServiceId]
  );

  return (
    <Checkbox
      data-hook={SetAsDefaultCheckboxDataHook.setAsDefaultCheckboxToggle}
      label={
        <Text data-hook={SetAsDefaultCheckboxDataHook.setAsDefaultCheckboxLabel}>
          {localeKeys.checkout.defaultAddress.checkbox()}
        </Text>
      }
      checked={isSetAsDefaultChecked}
      onChange={({checked}) => setIsSetAsDefaultChecked(checked)}
    />
  );
};
