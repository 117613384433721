import React from 'react';
import {DeliveryMethodStepDataHook} from '../DeliveryMethodStep';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {CollapsedShippingInfo} from './CollapsedShippingInfo/CollapsedShippingInfo';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';

export enum DeliveryMethodSummaryDataHook {
  root = 'ContactAndAddressSummary.root',
  deliveryName = 'ContactAndAddressSummary.deliveryName',
  deliveryTime = 'ContactAndAddressSummary.deliveryTime',
  deliveryPrice = 'ContactAndAddressSummary.deliveryPrice',
}

export const DeliveryMethodCollapsed = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();

  return (
    <div data-hook={DeliveryMethodStepDataHook.collapsed}>
      {!checkout.selectedShippingOption?.pickupInfo && <CollapsedShippingInfo />}
      {checkout.selectedShippingOption?.pickupInfo && (
        <PickupInfoBox pickupInfo={checkout.selectedShippingOption.pickupInfo} />
      )}
    </div>
  );
};
