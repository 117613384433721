import React from 'react';
import {SectionNotification} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import {DeliveryMethodSectionDataHook} from '../DeliveryMethodSection';

export const UnsupportedShippingDestinationError = () => {
  const localeKeys = useLocaleKeys();

  return (
    <SectionNotification data-hook={DeliveryMethodSectionDataHook.noShippingMessage} type="error">
      <SectionNotification.Icon icon={<ErrorIcon />} />
      <SectionNotification.Text>
        {localeKeys.checkout.deliveryMethod.error.unsupportedRegion()}
      </SectionNotification.Text>
    </SectionNotification>
  );
};
