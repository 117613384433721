import {FIELDS_GAP, FORMS_TEMPLATE_IDS} from '../../constants';
import {FormError, FormValues, FormViewer, FormViewerHandle} from '@wix/form-viewer/widget';
import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {getContactFormOverrides} from './contactForm.utils';

export interface FormProps {
  formRef: React.MutableRefObject<FormViewerHandle | null>;
  formValues: FormValues;
  setFormValues: (value: ((prevState: FormValues) => FormValues) | FormValues) => void;
  formErrors: FormError[];
  setFormErrors: (value: ((prevState: FormError[]) => FormError[]) | FormError[]) => void;
}

export interface ContactFormProps extends FormProps {
  overridePhone?: boolean;
}

const ContactFormInternal = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  overridePhone,
}: ContactFormProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const i18n = useICUI18n();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={getContactFormOverrides({localeKeys, checkoutSettings, overridePhone})}
      formId={FORMS_TEMPLATE_IDS.CONTACT}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const ContactForm = React.memo(ContactFormInternal);
