import React from 'react';
import {Dialog, Text, Button, ButtonPriority, TextTypography, ButtonSize} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './LogoutDialog.st.css';

export enum LogoutDialogDataHook {
  dialog = 'LogoutDialog.dialog',
  title = 'LogoutDialog.title',
  content = 'LogoutDialog.content',
  primaryButton = 'LogoutDialog.primaryButton',
  secondaryButton = 'LogoutDialog.secondaryButton',
}
interface LogoutDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LogoutDialog = ({isOpen, onClose}: LogoutDialogProps) => {
  const localeKeys = useLocaleKeys();
  const {
    memberStore: {currentUserEmail},
    navigationStore: {onLogout},
  } = useControllerProps();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog data-hook={LogoutDialogDataHook.dialog} isOpen={isOpen} onClose={onClose}>
      <div className={classes.root}>
        <Text typography={TextTypography.largeTitle} data-hook={LogoutDialogDataHook.title}>
          {localeKeys.checkout.logOutModal.title()}
        </Text>
        <div className={classes.dialogTextContent}>
          <Text data-hook={LogoutDialogDataHook.content}>
            {localeKeys.checkout.logOutModal.body({memberEmail: currentUserEmail})}
          </Text>
        </div>
        <Button
          upgrade
          data-hook={LogoutDialogDataHook.secondaryButton}
          priority={ButtonPriority.basicSecondary}
          className={classes.dialogButton}
          onClick={onClose}>
          {localeKeys.checkout.logOutModal.secondaryButton()}
        </Button>
        <Button
          upgrade
          onClick={() => void onLogout()}
          size={ButtonSize.medium}
          data-hook={LogoutDialogDataHook.primaryButton}
          className={classes.dialogButton}>
          {localeKeys.checkout.logOutModal.mainButton()}
        </Button>
      </div>
    </Dialog>
  );
};
