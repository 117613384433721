import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {
  getContactDetailsFromContactFormValues,
  getContactFormCurrentStateWithUpdatedCountry,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {getVatFormInitialState, getVatFromVatFormValues} from '../../../../Form/VatIdForm/VatForm.utils';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';
import {FormInstanceData, useFormInstance} from '../../../../Form/useFormInstance';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';
import {updateMandatoryFields} from '../../../../../../domain/utils/cashier.utils';
import {usePaymentsApi} from '../../../../WithPaymentsApi/WithPaymentsApi';
import {ContactModel} from '../../../../../../domain/models/Contact.model';
import {isBillingEqualToShipping} from '../../../../../../domain/utils/billingDetails.utils';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../../../../../gql/graphql';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {StepId} from '../../../../../../types/app.types';

interface BillingFormData {
  contact: FormInstanceData;
  vat: FormInstanceData;
  address: FormInstanceData;
  isFormValid: () => Promise<boolean>;
  initForm: (billingInfo?: AddressWithContactModel) => void;
  updateContactCountry: (country: string) => void;
  getDataForSubmit: () => {contactDetails?: FullAddressContactDetailsFragment; address?: ApiAddressFragment};
  contactCountry?: string;
}

export type BillingDataContextType = {
  billingFormData: BillingFormData;
  setBillingSameAsShipping: React.Dispatch<React.SetStateAction<boolean>>;
  billingSameAsShipping: boolean;
  isShippingValidForBilling: boolean;
};

export const BillingDataContext = createContext({} as BillingDataContextType);

export function withBillingData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {
      paymentStore: {setCashierMandatoryFields},
      formsStore,
      stepsManagerStore: {activeStep},
      checkoutStore: {checkout, isShippingFlow},
    } = useControllerProps();
    const {paymentsApi} = usePaymentsApi();
    const billingFormData = useBillingFormData();

    const {withObservation} = useFunctionResultObservation();
    const validateBillingAddress = withObservation(formsStore, 'validateBillingAddress');

    const {selectedAddressesService, editMode, selectedAddressesServiceId} = useMemberDetailsData();
    const [isShippingValidForBilling, setIsShippingValidForBilling] = useState<boolean>(true);

    const calculateBillingSameAsShippingState = (): boolean => {
      return (
        isShippingFlow &&
        (!checkout.billingInfo || isBillingEqualToShipping(checkout.billingInfo, checkout.shippingDestination))
      );
    };

    const [billingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(calculateBillingSameAsShippingState);

    useEffect(
      () => {
        if (activeStep.stepId !== StepId.payment || !checkout.shippingDestination) {
          return;
        }

        async function validate() {
          await updateMandatoryFields(
            paymentsApi,
            setCashierMandatoryFields,
            checkout.shippingDestination?.address?.country
          );
          void validateBillingAddress(checkout.shippingDestination!).then((value) => {
            setIsShippingValidForBilling(value);
          });
        }
        void validate();
      },
      /* eslint-disable react-hooks/exhaustive-deps */ [activeStep.stepId]
    );

    useEffect(
      () => {
        if (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) {
          billingFormData.initForm(new AddressWithContactModel({addressesServiceId: ADD_NEW_ADDRESS_ID}));
        } else if (editMode) {
          billingFormData.initForm(selectedAddressesService);
        }
      },
      /* eslint-disable react-hooks/exhaustive-deps */ [editMode, selectedAddressesServiceId]
    );

    useEffect(() => {
      if (selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID) {
        void updateMandatoryFields(paymentsApi, setCashierMandatoryFields, selectedAddressesService?.address?.country);
      }
    }, [selectedAddressesServiceId]);

    return (
      <BillingDataContext.Provider
        value={{
          billingFormData,
          setBillingSameAsShipping,
          billingSameAsShipping,
          isShippingValidForBilling,
        }}>
        <Component {...props} />
      </BillingDataContext.Provider>
    );
  };
}

export function useBillingData() {
  const {billingFormData, setBillingSameAsShipping, billingSameAsShipping, isShippingValidForBilling} =
    useContext(BillingDataContext);

  return {
    contactFormData: billingFormData.contact.data,
    vatFormData: billingFormData.vat.data,
    addressFormData: billingFormData.address.data,

    isFormValid: billingFormData.isFormValid,
    initForm: billingFormData.initForm,
    updateContactCountry: billingFormData.updateContactCountry,
    getBillingFormDataForSubmit: billingFormData.getDataForSubmit,

    contactCountry: billingFormData.contactCountry,

    setBillingSameAsShipping,
    billingSameAsShipping,

    isShippingValidForBilling,
  };
}

function useBillingFormData(): BillingFormData {
  const {
    checkoutStore: {checkout},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const hasBillingInfo = Boolean(checkout.billingInfo);

  const initialContactDetails = hasBillingInfo ? checkout.billingInfo?.contact : checkout.shippingDestination?.contact;
  const initialAddress = hasBillingInfo ? checkout.billingInfo?.address : checkout.shippingDestination?.address;

  const contactForm = useFormInstance(
    getContactFormInitialState({
      checkoutSettings,
      contact: initialContactDetails,
      country: initialAddress?.country,
    })
  );
  const [contactCountry, setContactCountry] = useState(initialAddress?.country);
  const vatForm = useFormInstance(getVatFormInitialState(checkout.billingInfo?.contact));
  const addressForm = useFormInstance(getAddressFormInitialState(checkoutSettings, initialAddress));

  const forms = [contactForm, vatForm, addressForm];

  const isFormValid = async () => {
    const areFormsValidArr = await Promise.all(forms.map(({isValid}) => isValid()));

    return !areFormsValidArr.includes(false);
  };

  const initForm = (billingInfo: AddressWithContactModel | undefined = checkout.billingInfo) => {
    const initialAddress = billingInfo ? billingInfo?.address : checkout.shippingDestination?.address;
    contactForm.data.setFormValues(
      getContactFormInitialState({
        checkoutSettings,
        contact: getContactDetailsForForm(billingInfo),
        country: initialAddress?.country,
      })
    );
    vatForm.data.setFormValues(getVatFormInitialState(billingInfo?.contact));
    addressForm.data.setFormValues(getAddressFormInitialState(checkoutSettings, initialAddress));
  };

  const getContactDetailsForForm = (billingInfo: AddressWithContactModel | undefined): ContactModel | undefined => {
    if (billingInfo?.addressesServiceId === ADD_NEW_ADDRESS_ID) {
      return undefined;
    }

    return billingInfo ? billingInfo?.contact : checkout.shippingDestination?.contact;
  };

  const areFormsRendered = () => forms.some((form) => form.isRendered());

  const updateContactCountry = (country: string) => {
    setContactCountry(country);
    contactForm.data.setFormValues(
      getContactFormCurrentStateWithUpdatedCountry({
        contactFormValues: contactForm.data.formValues,
        country,
      })
    );
  };

  const getDataForSubmit = (): {contactDetails?: FullAddressContactDetailsFragment; address?: ApiAddressFragment} => {
    if (!areFormsRendered()) {
      return {};
    }

    const contactDetails = getContactDetailsFromContactFormValues(contactForm.data.formValues, checkoutSettings);
    const address = getAddressFromAddressFormValues(checkoutSettings, addressForm.data.formValues);
    const vatId = getVatFromVatFormValues(vatForm.data.formValues);

    return {
      contactDetails: {
        ...contactDetails,
        ...(vatId ? {vatId} : {}),
      },
      address,
    };
  };

  return {
    contact: contactForm,
    vat: vatForm,
    address: addressForm,
    isFormValid,
    initForm,
    updateContactCountry,
    getDataForSubmit,
    contactCountry,
  };
}
