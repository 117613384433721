import {classes} from '../Components/CustomerDetailsOpen.st.css';
import {FIELDS_GAP} from '../../../../constants';
import {EmailForm} from '../../../../Form/EmailForm/EmailForm';
import {ContactForm} from '../../../../Form/ContactForm/ContactForm';
import {AdditionalInfoForm} from '../../../../Form/AdditionalInfoForm/AdditionalInfoForm';
import {AddressForm} from '../../../../Form/AddressForm/AddressForm';
import React from 'react';
import {CustomerDetailsDataHook} from '../Components/CustomerDetailsOpen';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useCustomerDetailsData} from '../WithCustomerDetailsData/WithCustomerDetailsData';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';

export const CustomerDetailsForm = () => {
  const {
    checkoutStore: {isShippingFlow},
    memberStore: {isMember},
    formsStore: {areFormsLoaded},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();
  const {contactFormData, emailFormData, additionalInfoFormData, addressFormData} = useCustomerDetailsData();

  if (!areFormsLoaded) {
    return <FormLoader />;
  }

  return (
    <div
      className={classes.forms}
      style={{
        gap: `${FIELDS_GAP}px`,
      }}>
      {!isMember && (
        <div data-hook={CustomerDetailsDataHook.emailForm}>
          <EmailForm {...emailFormData} />
        </div>
      )}
      <div data-hook={CustomerDetailsDataHook.contactForm}>
        <ContactForm {...contactFormData} />
      </div>
      {checkoutSettings.customField.show && (
        <div data-hook={CustomerDetailsDataHook.additionalInfoForm}>
          <AdditionalInfoForm {...additionalInfoFormData} />
        </div>
      )}
      {isShippingFlow && (
        <div data-hook={CustomerDetailsDataHook.addressForm}>
          <AddressForm {...addressFormData} />
        </div>
      )}
    </div>
  );
};
