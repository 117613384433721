import React from 'react';
import {Text, TextPriority} from 'wix-ui-tpa';
import {useControllerProps} from '../../../../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {AppliedCouponModel} from '../../../../../../../domain/models/AppliedCoupon.model';

export enum CollapsedShippingInfoDataHook {
  root = 'CollapsedShippingInfo.root',
  deliveryName = 'CollapsedShippingInfo.deliveryName',
  deliveryTime = 'CollapsedShippingInfo.deliveryTime',
  deliveryPrice = 'CollapsedShippingInfo.deliveryPrice',
}

export const CollapsedShippingInfo = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  const getPriceText = () => {
    if (
      checkout.priceSummary.shipping.amount === 0 ||
      (checkout.appliedCoupon && isFreeShippingCoupon(checkout.appliedCoupon))
    ) {
      return localeKeys.checkout.order_summary.totals.free();
    }
    return checkout.priceSummary.shipping.formattedAmount;
  };

  return (
    <Text data-hook={CollapsedShippingInfoDataHook.root} priority={TextPriority.secondary}>
      <span data-hook={CollapsedShippingInfoDataHook.deliveryName}>{checkout.selectedShippingOption?.title}</span>
      {checkout.selectedShippingOption?.deliveryTime && (
        <span data-hook={CollapsedShippingInfoDataHook.deliveryTime}>
          {` (${checkout.selectedShippingOption?.deliveryTime})`}
        </span>
      )}
      <span data-hook={CollapsedShippingInfoDataHook.deliveryPrice}> {getPriceText()}</span>
    </Text>
  );
};

const isFreeShippingCoupon = (coupon: AppliedCouponModel): boolean => coupon.couponType === 'FreeShipping';
